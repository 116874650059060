.header{
    display: flex;
    justify-content: space-between;

}
.logo{
    width: 10rem;
    height: 3rem;
}
 
.header-menue{
    list-style: none;
    display: flex ;
    gap: 2rem;
    color: white;

}
.header-menue>li:hover{
    color: orange;
    cursor: pointer;
}
